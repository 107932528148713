<template lang="pug">
transition(name="fade")
  div(
    v-show="effectiveShow",
    :id="id",
    class="collapse"
    :class="{ 'show': effectiveShow }"
  )
    slot
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  isOpen: {
    type: Boolean,
    default: null,
  },
});

const emit = defineEmits(['update-state']);

const { registerCollapse, getCollapseState } = useCollapse();

registerCollapse(props.id);

// Internal state of the component to handle the collapse state.
const internalShow = ref(getCollapseState(props.id));

// Determine the effective state of the collapse,
// it prioritizes the externally provided prop over the internal state.
const effectiveShow = computed(() => {
  return props.isOpen !== null ? props.isOpen : internalShow.value;
});

// Emitting an event when the state changes
// This can be useful to inform the parent component of changes.
watch(effectiveShow, (newVal) => {
  emit('update-state', newVal);
});
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition:
    max-height 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
  overflow: hidden;
}
.fade-enter-from,
.fade-leave-to {
  max-height: 0;
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  max-height: 500px;
  opacity: 1;
}
</style>
